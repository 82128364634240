import React from "react";
import { Button, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CivicSight from "./assets/civic-sight.png";
import Leitner from "./assets/leitner.png";

const Footer = () => {
  const classes = useStyles();

  const handleShare = (method) => {
    if (method === "fb") {
      window.open("https://www.facebook.com/knowyourrightshk/");
    } else if (method === "tw") {
      window.open("https://twitter.com/kyr_hk");
    } else if (method === "ig") {
      window.open("https://instagram.com/kyr_hongkong");
    }
  };

  return (
    <div className={classes.footer}>
      <Container className={classes.container}>
        <div className={classes.topRow}>
          <div className={classes.logoGroup}>
            <div>
              <div className={classes.engText}>Know Your Rights HK</div>
              <div className={classes.chiText}>知道你的權益</div>
            </div>
            <a
              className={classes.logoCivic}
              href="https://civicsight.org/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={CivicSight} alt="civic-sight 公民透視" />
            </a>
            <a
              className={classes.logoLeitner}
              href="http://www.leitnercenter.org/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Leitner} alt="Leitner Center" />
            </a>
          </div>
          <div className={classes.shareGroup}>
            <Button
              className={classes.button}
              onClick={() => handleShare("ig")}
            >
              <img src="/ig_white.svg" alt="IG share" />
            </Button>
            <Button
              className={classes.button}
              onClick={() => handleShare("fb")}
            >
              <img src="/fb_white.svg" alt="Facebook share" />
            </Button>
            <Button
              className={classes.button}
              onClick={() => handleShare("tw")}
            >
              <img src="/tw_white.svg" alt="Twitter share" />
            </Button>
          </div>
        </div>
        <div className={classes.bottomRow}>
          <div>© {new Date().getFullYear()} Know Your Rights HK</div>
          <div className={classes.textGroup}>
            <div>
              <a
                href="https://civicsight.org/terms-conditions/"
                className={classes.link}
              >
                Terms & Conditions
              </a>
            </div>
            <div>
              <a
                href="https://civicsight.org/disclaimer/"
                className={classes.link}
                target="_blank"
                rel="noreferrer"
              >
                Disclaimer
              </a>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  topRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: "60px",
    },
  },
  bottomRow: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "30px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginTop: "40px",
      gap: "10px",
    },
  },
  shareGroup: {
    display: "flex",
    flexDirection: "row",
  },
  logoGroup: {
    display: "flex",
    flexDirection: "row",
    "& > *": {
      paddingRight: "40px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "20px",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "40px",
      "& > *": {
        paddingRight: "20px",
      },
    },
  },
  logoLeitner: {
    display: "flex",
    alignItems: "center",
    "& > img": {
      maxHeight: "44px",
    },
  },
  logoCivic: {
    display: "flex",
    alignItems: "center",
    "& > img": {
      maxHeight: "30px",
    },
  },
  textGroup: {
    display: "flex",
    alignItems: "center",
    "& > *": {
      padding: "0px 8px",
      fontSize: "13px",
      lineHeight: "15px",
      fontWeight: 500,
    },
    "& > *:not(:first-child)": {
      borderLeft: "1px solid #25262C",
    },
    "& > *:first-child": {
      paddingLeft: "0px",
    },
  },
  chiText: {
    fontWeight: 900,
    fontSize: "17px",
    lineHeight: "24px",
    letterSpacing: "0.2em",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
  engText: {
    fontWeight: 900,
    fontSize: "18px",
    lineHeight: "22px",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
  button: {
    minWidth: "42px",
  },
  footer: {
    minHeight: "100px",
    padding: "40px 0px",
    borderTop: "1px solid #25262C",
    [theme.breakpoints.down("xs")]: {
      marginTop: "4px",
    },
  },
  link: {
    // textDecoration: 'none',
    color: "#25262C",
    fontWeight: 700,
  },
}));

export default Footer;
