import React, { useState } from "react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import MainBody from "./MainBody";
import guidanceForJournalistsObj from "./content/guidance-for-journalists.json";

function ForJournalists() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [chapter] = useState("");

  return (
    <>
      <Container className={classes.container}>
        <MainBody GodObj={guidanceForJournalistsObj} changeChapter={chapter} />
      </Container>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: "0 !important",
    "& a": {
      color: "white",
    },
  },
  card: {
    border: `1px solid ${guidanceForJournalistsObj.colorTheme.mainColor}`,
    backgroundColor: "transparent",
    marginTop: "40px",
    padding: "60px 100px",
    boxShadow: "none",
    fontFamily: "Manrope",
    whiteSpace: "pre-line",
    [theme.breakpoints.down("sm")]: {
      padding: "40px 20px",
    },
  },
  title: {
    fontSize: "22px",
    fontWeight: 700,
    lineHeight: 1.2,
    marginBottom: "32px",
    [theme.breakpoints.down("sm")]: {
      // fontSize: '24px'
    },
  },
  container: {
    marginTop: "100px",
    fontSize: "16px",
    lineHeight: 1.5,
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
      fontSize: "15px",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "1408px",
    },
  },
}));

export default ForJournalists;
