import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  IconButton,
  Drawer,
  Snackbar,
  Tabs,
  Tab,
  Toolbar,
} from "@material-ui/core";
import { Clear as ClearIcon, Dehaze as DehazeIcon } from "@material-ui/icons";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { copyTextToClipboard } from "./copyToClipboard";
import KyrLogo from "./assets/kyr-logo-v2.svg";
import ShareIcon from "./assets/share_white.svg";

function Nav(props) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  let location = useLocation();
  const history = useHistory();

  const handleShare = (method) => {
    if (method === "copy") {
      setShowSnackbar(true);
      // navigator.clipboard.writeText(window.location.hostname)
      copyTextToClipboard(window.location.hostname);
    } else if (method === "fb") {
      window.open("https://www.facebook.com/knowyourrightshk/");
    } else if (method === "tw") {
      window.open("https://twitter.com/kyr_hk");
    } else if (method === "ig") {
      window.open("https://instagram.com/kyr_hongkong");
    }
  };

  const handleLanguageChange = (lang) => {
    history.replace(
      location.pathname.replace("/" + i18n.language + "/", "/" + lang + "/")
    );
    i18n.changeLanguage(lang);
  };

  const path = location.pathname.substr(3);
  const page = (() => {
    if (path.indexOf("/home") > -1) {
      return "home";
    }
    if (path.indexOf("/due") > -1) {
      return "due";
    }
    if (path.indexOf("/legal-aid") > -1) {
      return "legal-aid";
    }
    if (path.indexOf("/national-security-law") > -1) {
      return "nsl";
    }
    if (path.indexOf("/money-laundering") > -1) {
      return "ml";
    }
    if (path.indexOf("/guidance-for-journalists") > -1) {
      return "guidance-for-journalists";
    }
  })();

  // scroll hide menu
  const [hideNav, setHideNav] = useState(false);
  let prevScrollpos = window.pageYOffset;
  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos || currentScrollPos < 150) {
      setHideNav(false);
    } else if (currentScrollPos > 150) {
      setHideNav(window.innerWidth < 960);
    }
    prevScrollpos = currentScrollPos;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div
        className={[classes.barbg, hideNav ? "hide" : ""].join(" ")}
        style={{ background: props.backgroundColor }}
      >
        <Container className={classes.container}>
          <Toolbar
            className={classes.toolbar}
            // style={menuOpen ? {background: props.backgroundColor} : null}
          >
            <Link to={`/${i18n.language}/home`}>
              <img src={KyrLogo} alt="KYRHK" className={classes.logo} />
            </Link>
            <div className={classes.tabsContainer}>
              <PageTabs
                className={classes.desktopOnly}
                value={page}
                indicatorColor="primary"
              >
                <PageTab
                  value="nsl"
                  component={Link}
                  to={`/${i18n.language}/national-security-law`}
                  label={t("NSL")}
                />
                <PageTab
                  value="ml"
                  component={Link}
                  to={`/${i18n.language}/money-laundering`}
                  label={t("Money Laundering")}
                />
                <PageTab
                  value="due"
                  component={Link}
                  to={`/${i18n.language}/due-process`}
                  label={t("Police Encounters")}
                />
                <PageTab
                  value="legal-aid"
                  component={Link}
                  to={`/${i18n.language}/legal-aid`}
                  label={t("Legal Aid")}
                />
                <PageTab
                  value="guidance-for-journalists"
                  component={Link}
                  to={`/${i18n.language}/guidance-for-journalists`}
                  label={t("For journalists")}
                />
              </PageTabs>
              <div className={classes.desktopOnly}>
                <Button style={buttonStyles} onClick={() => handleShare("ig")}>
                  <img src={"/ig_white.svg"} alt="IG share" width="28" />
                </Button>
                <Button style={buttonStyles} onClick={() => handleShare("fb")}>
                  <img src={"/fb_white.svg"} alt="Facebook share" width="28" />
                </Button>
                <Button style={buttonStyles} onClick={() => handleShare("tw")}>
                  <img src={"/tw_white.svg"} alt="Twitter share" width="28" />
                </Button>
              </div>
              <div className={classes.verticalDivider} />
              <IconButton
                className={`${classes.menuIcon} ${classes.mobileOnly}`}
                onClick={() => setMenuOpen(!menuOpen)}
              >
                {menuOpen ? <ClearIcon /> : <DehazeIcon />}
              </IconButton>
              <LanguageTabs
                value={i18n.language}
                onChange={(e, v) => handleLanguageChange(v)}
              >
                <LanguageTab value="zh" label="繁" />
                <LanguageTab value="en" label="En" />
              </LanguageTabs>
              <Drawer
                anchor={"top"}
                open={menuOpen}
                onClose={() => {
                  setMenuOpen(false);
                }}
                classes={{ paper: classes.menuDrawerPaper }}
              >
                <div className={classes.drawerDottedLine} />
                <div>
                  <PageTabs value={page} indicatorColor="primary">
                    <PageTab
                      value="nsl"
                      component={Link}
                      to={`/${i18n.language}/national-security-law`}
                      label={t("NSL")}
                      onClick={() => setMenuOpen(false)}
                    />
                    <PageTab
                      value="ml"
                      component={Link}
                      to={`/${i18n.language}/money-laundering`}
                      label={t("Money Laundering")}
                      onClick={() => setMenuOpen(false)}
                    />
                    <PageTab
                      value="due"
                      component={Link}
                      to={`/${i18n.language}/due-process`}
                      label={t("Police Encounters")}
                      onClick={() => setMenuOpen(false)}
                    />
                    <PageTab
                      value="legal-aid"
                      component={Link}
                      to={`/${i18n.language}/legal-aid`}
                      label={t("Legal Aid")}
                      onClick={() => setMenuOpen(false)}
                    />
                    <PageTab
                      value="guidance-for-journalists"
                      component={Link}
                      to={`/${i18n.language}/guidance-for-journalists`}
                      label={t("For journalists")}
                      onClick={() => setMenuOpen(false)}
                    />
                  </PageTabs>
                </div>
                <div className={classes.drawerButtonGroup}>
                  <Button
                    style={buttonStyles}
                    onClick={() => handleShare("ig")}
                  >
                    <img src={"/ig_white.svg"} alt="IG share" width="28" />
                  </Button>
                  <Button
                    style={buttonStyles}
                    onClick={() => handleShare("fb")}
                  >
                    <img
                      src={"/fb_white.svg"}
                      alt="Facebook share"
                      width="28"
                    />
                  </Button>
                  <Button
                    style={buttonStyles}
                    onClick={() => handleShare("tw")}
                  >
                    <img src={"/tw_white.svg"} alt="Twitter share" width="28" />
                  </Button>
                </div>
                <div className={classes.drawerDottedLine} />
              </Drawer>
            </div>
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              autoHideDuration={2000}
              onClose={() => setShowSnackbar(false)}
              open={showSnackbar}
              message="Copy to clipboard"
            />
          </Toolbar>
        </Container>
      </div>
      <div className={classes.spacing} />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: "0px",
    paddingRight: "0px",
    maxWidth: "100vw",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
      marginRight: "0px",
    },
  },
  barbg: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      top: 0,
      zIndex: 1301,
      transition: "top 0.5s ease-in-out",
    },
    "&.hide": {
      [theme.breakpoints.down("sm")]: {
        top: "-90px",
      },
    },
  },
  spacing: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      height: "90px",
      width: "100%",
      display: "block",
      position: "relative",
    },
  },
  desktopOnly: {
    display: "flex",
    [`@media (max-width: 1024px)`]: {
      display: "none",
    },
  },
  mobileOnly: {
    [`@media (min-width: 1025px)`]: {
      display: "none",
    },
  },
  menuIcon: {
    position: "relative", // Add position relative for absolute positioning of border
    borderRadius: "unset",
    height: "41px",
    color: "#FEFBFA",
    marginTop: "5px",
    padding: "3px 12px !important",
    paddingLeft: "20px !important",
    alignItems: "flex-start",
    "&::before": {
      // Create pseudo-element for border
      content: '""',
      position: "absolute",
      left: 0,
      top: 0,
      height: "30px", // Desired border height
      borderLeft: "2px #25262C solid",
    },
  },
  tabsContainer: {
    display: "flex",
    [`@media (max-width: 1024px)`]: {
      flexDirection: "row-reverse",
    },
  },
  logo: {
    marginTop: "3px",
    maxHeight: "70px",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "60px",
    },
    [theme.breakpoints.down("xs")]: {
      maxHeight: "50px",
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    zIndex: theme.zIndex.drawer * 2,
    paddingTop: "20px",
    paddingBottom: "20px",
    paddingLeft: "60px",
    paddingRight: "60px",
    height: "115px",
    [`@media (max-width: 1024px)`]: {
      paddingTop: "20px",
      height: "90px",
      alignItems: "flex-start",
    },
    [`@media (max-width: 1366px)`]: {
      paddingLeft: "24px",
      paddingRight: "24px",
    },
  },
  verticalDivider: {
    borderRight: "2px #25262C solid",
    height: "32px",
    marginLeft: "22px",
    marginRight: "12px",
    marginTop: "8px",
    [`@media (max-width: 1024px)`]: {
      display: "none",
    },
  },
  shareButton: {
    minWidth: "42px",
    borderRadius: "unset",
    [theme.breakpoints.up("md")]: {
      borderRight: "2px #25262C solid",
      paddingLeft: "15px",
      paddingRight: "20px",
      marginRight: "20px",
    },
  },
  menuDrawerPaper: {
    margin: "120px 20px 30px 20px",
    height: "calc(100vh - 120px - 30px)",
    width: "calc(100vw - 40px)",
    background: "white",
    borderRadius: "30px",
    boxShadow: "4px 4px 25px rgba(0, 0, 0, 0.15)",
    padding: "0px",
    justifyContent: "center",
    alignItems: "center",
    "& > *": {
      margin: "10px 0px",
    },
    "& a": {
      padding: "0px",
      minHeight: "42px",
      fontWeight: 400,
      "&.Mui-selected": {
        fontWeight: 900,
        "& span": {
          borderBottom: "none",
        },
      },
    },
  },
  drawerButtonGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "24px",
  },
  drawerDottedLine: {
    width: "30px",
    height: "0px",
    border: "2px dashed #DB4B38",
    transform: "rotate(90deg)",
    display: "none",
  },
}));

const PageTabs = withStyles((theme) => ({
  indicator: {
    backgroundColor: "transparent",
  },
  flexContainer: {
    marginRight: "25px",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      flexDirection: "column",
    },
  },
}))(Tabs);

const PageTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: "600",
    opacity: 1,
    color: "#FFF",
    minWidth: 0,
    padding: "6px 0px",
    paddingLeft: "24px",
    [theme.breakpoints.down("sm")]: {
      color: "#25262C",
      paddingLeft: "0px",
    },
  },
  selected: {
    color: "#25262C",
    fontWeight: 800,
    "& > .MuiTab-wrapper": {
      borderBottom: "2px #25262C solid",
    },
  },
  wrapper: {
    width: "inherit",
  },
}))((props) => <Tab disableRipple {...props} />);

const LanguageTabs = withStyles((theme) => ({
  root: {
    borderBottom: "none",
    minHeight: 24,
    "& .MuiTabs-flexContainer": {
      paddingTop: "3px",
      [`@media (max-width: 1024px)`]: {
        paddingRight: "12px",
      },
    },
    "& .MuiTabs-flexContainer .MuiButtonBase-root": {
      [`@media (max-width: 1024px)`]: {
        padding: "2px",
      },
    },
  },
  indicator: {
    backgroundColor: "transparent",
  },
}))(Tabs);

const LanguageTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 36,
    minHeight: 30,
    fontWeight: 900,
    marginRight: theme.spacing(0),
    fontSize: "15px",
    opacity: 1,
    padding: "6px 6px",
  },
  selected: {
    "& > .MuiTab-wrapper": {
      color: "#FEFBFA",
      backgroundColor: "#25262C",
    },
  },
  wrapper: {
    color: "#FEFBFA",
    borderRadius: "30px",
    padding: "2px 10px 2px 10px",
  },
}))((props) => <Tab disableRipple {...props} />);

const buttonStyles = {
  minWidth: "42px",
  padding: "6px",
};

export default Nav;
