import React, { useState } from "react";
import {
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import MainBody from "./MainBody";
import moneyLaunderingObj from "./content/money-laundering.json";

function MoneyLaundering() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [chapter] = useState("");

  return (
    <>
      <Container className={classes.container}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography className={classes.title}>
              {t("What is money laundering?")}
            </Typography>
            <Grid container spacing={5}>
              <Grid
                item
                sm={12}
                md={6}
                dangerouslySetInnerHTML={{
                  __html: t(
                    "Money laundering is a criminal offence in which the proceeds of illegal activity, whether money or assets, are made to appear to have come from a legal source. This is done through processing the dirty proceeds through legitimate businesses and financial institutions to “launder” it, or make it appear clean.\n\n<span style='font-size:22px;font-weight: 700;'>Money laundering today in Hong Kong</span>\n\nHong Kong’s national security law criminalises acts that undermine the authority of the government, that aim to break Hong Kong away from China and that could be deemed as terrorism, as well as any collusion with foreign forces. If any transaction, from openly fund-raising for a cause to just receiving money in an account, can be connected to these broad areas, then those involved are potentially at risk of falling foul of the law."
                  ),
                }}
              />
              <Grid
                item
                sm={12}
                md={6}
                dangerouslySetInnerHTML={{
                  __html: t(
                    "Financial transactions are, therefore, under increased scrutiny, with authorities trying to prevent the transfer of money that could be used for purposes they perceive to be threatening national security. This comes after organisations and crowdfunding initiatives were targeted by the government for supporting protesters in 2019.\n\nIn addition, Hong Kong is <a href='https://hongkongfp.com/2023/02/21/proposed-hong-kong-crowdfunding-law-will-hinder-survival-of-civil-groups-says-pro-democracy-group/'>planning new crowd-funding legislation</a> that will formally ban money-raising efforts that threatens “public interest, public safety, or national security in Hong Kong”, which can be interpreted broadly. Both those who donate and who organise the crowdfunding would be liable for prosecution. Even under existing laws, people who donate to a cause that they know can be linked to a national security offence can be charged with money laundering as well as &ldquo;financing&rdquo; the offence under the national security law legislation."
                  ),
                }}
              />
            </Grid>
          </CardContent>
        </Card>
        <MainBody GodObj={moneyLaunderingObj} changeChapter={chapter} />
      </Container>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: "0 !important",
    "& a": {
      fontWeight: 700,
      color: "#25262C",
    },
  },
  card: {
    border: `1px solid ${moneyLaunderingObj.colorTheme.mainColor}`,
    backgroundColor: "transparent",
    marginTop: "40px",
    padding: "60px 100px",
    boxShadow: "none",
    fontFamily: "Manrope",
    whiteSpace: "pre-line",
    [theme.breakpoints.down("sm")]: {
      padding: "40px 20px",
      marginBottom: "60px",
    },
  },
  title: {
    fontSize: "22px",
    fontWeight: 700,
    lineHeight: 1.2,
    marginBottom: "32px",
    [theme.breakpoints.down("sm")]: {
      // fontSize: '24px'
    },
  },
  container: {
    marginTop: "80px",
    fontSize: "16px",
    lineHeight: 1.4,
    [theme.breakpoints.down("xs")]: {
      marginTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
      fontSize: "16px",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "1408px",
    },
  },
}));

export default MoneyLaundering;
