import React from "react";
import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import HomeBannerImg from "./assets/home-banner.png";

function HomeBanner() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div>
      <Container>
        <div className={classes.containerStyles}>
          <div className={classes.textBox}>
            <h3 className={classes.subTitle}>
              An Access<span>Justice</span> initiative by{" "}
              <span>CivicSight</span>
            </h3>
            <div className={classes.separator}></div>
            <h1 className={classes.engText}>Know Your Rights HK</h1>
            <Typography className={classes.chiText}>人人有權</Typography>
            <div className={classes.descBox}>
              <Typography
                className={classes.descText}
                dangerouslySetInnerHTML={{
                  __html: t(
                    "Hong Kong credits its success to a strong rule of law. For this vision to remain true, the law, the justice system and its remedies have to be accessible, just and real to everyone in the city.<br/><br/><strong>#KnowYourRightsHK</strong> is here to help you understand and exercise your legal rights. Whatever your background and circumstances, you are entitled to certain fundamental rights guaranteed under the laws of Hong Kong and international law.<br/><br/>Join us in advocating for a resilient rule of law in Hong Kong, get in touch and follow us on social media."
                  ),
                }}
              />
            </div>
          </div>
          <div className={classes.image}>
            <img
              src={HomeBannerImg}
              className={classes.img}
              alt="Know Your Rights HK"
            />
          </div>
        </div>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  subTitle: {
    margin: 0,
    fontSize: "15px",
    lineHeight: "15.75px",
    fontWeight: "700",
    color: "#FEFBFA",
    "& > span": {
      color: theme.palette.text.primary,
      fontWeight: "800",
    },
  },
  separator: {
    display: "block",
    width: "80px",
    height: "2px",
    background: "#FEFBFA",
    marginBottom: "30px",
    marginTop: "40px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "40px",
      marginTop: "20px",
    },
  },
  hashTag: {
    display: "inline-block",
    width: "auto",
    color: "#FEFBFA",
    opacity: 0.3,
    fontSize: "33px",
    fontWeight: 900,
    lineHeight: 1.1,
    letterSpacing: "0.1em",
    marginBottom: "20px",
    "& > span": {
      display: "block",
      width: "100%",
      height: "6px",
      background: "#FEFBFA",
      [theme.breakpoints.down("sm")]: {
        height: "5px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "26px",
    },
  },
  image: {
    maxWidth: "622px",
    width: "50%",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      minWidth: "300px",
      maxWidth: "420px",
      alignSelf: "center",
      margin: "20px 0",
    },
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  textBox: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      //paddingTop: '40px'
      width: "100%",
    },
  },
  engText: {
    fontFamily: "Manrope",
    fontWeight: 900,
    fontSize: "44px",
    lineHeight: 1.36,
    color: "#FEFBFA",
    position: "relative",
    margin: 0,
    letterSpacing: "-0.02em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
    },
  },
  chiText: {
    fontFamily: "'Noto Sans TC', sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "44px",
    lineHeight: 1.4,
    letterSpacing: "0.12em",
    position: "relative",
    color: "#FEFBFA",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
    },
  },
  descBox: {
    marginTop: "40px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px",
    },
  },
  descText: {
    fontSize: "16px",
    lineHeight: 1.5,
    color: "#FEFBFA",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  containerStyles: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    overflow: "hidden",
    padding: "120px 0 130px",
    gap: "100px",
    [theme.breakpoints.down("md")]: {
      gap: "60px",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "60px 0",
      gap: "30px",
    },
  },
}));

export default HomeBanner;
